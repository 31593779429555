// src/pages/index.js
import RentalInquiryModal from "@/components/forms/RentalInquiryModal";
import MetaHead from "@/components/seo/MetaHead";
import { useState } from "react";

export default function Home() {
	const [isOpenRentalInquiryModal, setIsOpenRentalInquiryModal] = useState(false);

	const openRentalInquiryModal = () => setIsOpenRentalInquiryModal(true);
	const closeRentalInquiryModal = () => setIsOpenRentalInquiryModal(false);

	const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

	return (
		<div>
			<MetaHead
				title="Student Apartments In The Annex | Riverview Residences"
				description="Experience unparalleled student living at Riverview Residences, located in the heart of the city, close to campuses, cultural hubs, and nightlife."
				url={baseUrl}
				image="https://cdn.rentiq.com/samples/riverview-residences/optimized/home.webp"
			/>
			<main>
				<div
					className="relative bg-no-repeat bg-center bg-cover"
					style={{
						height: "100vh",
						backgroundImage: `
							url(https://cdn.rentiq.com/samples/riverview-residences/optimized/home.webp),
							url(https://cdn.rentiq.com/samples/riverview-residences/fallback/home.jpeg)
						`,
					}}
				>
					<div className="absolute inset-0 bg-black opacity-50"></div>
					<div
						className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"
						style={{ paddingTop: "var(--header-height)" }}
					>
						<h1 className="text-white text-4xl md:text-5xl font-bold mb-6">Welcome to Riverview Residences</h1>
						<button
							className="text-white border-purple bg-purple rounded-full px-8 py-3 font-bold transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
							onClick={openRentalInquiryModal}
						>
							Inquire Now
						</button>
					</div>
				</div>
				<RentalInquiryModal
					isOpenRentalInquiryModal={isOpenRentalInquiryModal}
					onCloseRentalInquiryModal={closeRentalInquiryModal}
				/>

				{/* Content below the header image */}
				<div className="container mx-auto px-4 py-8">
					<div className="flex flex-col md:flex-row gap-10 mb-8">
						<div className="md:flex-1 order-2 md:order-1">
							<h2 className="text-2xl font-semibold text-purple mb-4">Premier Student Lifestyle</h2>
							<p className="mb-4 leading-relaxed">
								At Riverview Residences, immerse yourself in an elite student living experience that harmoniously blends
								luxury with the convenience of urban life. Strategically situated, our residence offers unrivaled access
								to educational institutions, cultural hotspots, and dynamic nightlife, crafting a student experience
								that is both enriching and exciting.
							</p>
							<p className="mb-4 leading-relaxed">
								Our commitment to excellence is evident in every detail, from sophisticated living spaces to
								comprehensive amenities that cater to every aspect of student life. Riverview Residences isn&rsquo;t
								just a place to stay; it&rsquo;s where your academic journey thrives in a community designed for
								success.
							</p>
						</div>
						<div className="md:flex-1 order-1 md:order-2">
							<picture>
								<source
									srcSet="https://cdn.rentiq.com/samples/riverview-residences/optimized/kitchen.webp"
									type="image/webp"
								/>
								<img
									src="https://cdn.rentiq.com/samples/riverview-residences/fallback/kitchen.jpeg"
									alt="Campus Life"
									loading="lazy"
									className="w-full object-cover"
								/>
							</picture>
						</div>
					</div>

					<div className="flex flex-col md:flex-row gap-10 mb-8">
						<div className="md:flex-1">
							<picture>
								<source
									srcSet="https://cdn.rentiq.com/samples/riverview-residences/optimized/workspace.webp"
									type="image/webp"
								/>
								<img
									src="https://cdn.rentiq.com/samples/riverview-residences/fallback/workspace.jpeg"
									alt="Study Spaces"
									loading="lazy"
									className="w-full object-cover md:order-1"
								/>
							</picture>
						</div>
						<div className="md:flex-1">
							<h2 className="text-2xl font-semibold text-purple mb-4">Modern Study Spaces</h2>
							<p className="mb-4 leading-relaxed">
								Discover the epitome of academic excellence in our modern study spaces. Designed with the latest in
								design and technology, these areas cater to both focused individual study and dynamic group
								collaborations. The thoughtful layout and advanced resources ensure an effective and enriching learning
								environment.
							</p>
							<p className="mb-4 leading-relaxed">
								Each space is engineered to enhance productivity and inspire innovation, offering the perfect backdrop
								for academic pursuits. Whether you&rsquo;re working on a solo project or a team assignment, our study
								spaces are equipped to meet your educational needs.
							</p>
						</div>
					</div>

					<div className="flex flex-col md:flex-row gap-10 mb-8">
						<div className="md:flex-1 order-2 md:order-1">
							<h2 className="text-2xl font-semibold text-purple mb-4">Luxury Amenities</h2>
							<p className="mb-4 leading-relaxed">
								Experience unparalleled luxury at Riverview Residences, where our architecturally significant design
								meets refined aesthetics. The lobby welcomes you with its elegant fireplace and plush seating, setting a
								tone of sophisticated comfort. Our fitness center is equipped with state-of-the-art exercise gear,
								supporting your wellness journey.
							</p>
							<p className="mb-4 leading-relaxed">
								The rooftop garden and terrace provide a serene escape with stunning city views, perfect for relaxation
								or hosting gatherings. Inside, the community lounge with its wet bar is ideal for social events,
								complementing the modern, technology-equipped study spaces that facilitate both individual and group
								learning.
							</p>
						</div>
						<div className="md:flex-1 order-1 md:order-2">
							<picture>
								<source
									srcSet="https://cdn.rentiq.com/samples/riverview-residences/optimized/amenities-2.webp"
									type="image/webp"
								/>
								<img
									src="https://cdn.rentiq.com/samples/riverview-residences/fallback/amenities-2.jpeg"
									alt="Amenities"
									loading="lazy"
									className="w-full object-cover"
								/>
							</picture>
						</div>
					</div>

					<div className="flex flex-col md:flex-row gap-10">
						<div className="md:flex-1">
							<picture>
								<source
									srcSet="https://cdn.rentiq.com/samples/riverview-residences/optimized/community-space.webp"
									type="image/webp"
								/>
								<img
									src="https://cdn.rentiq.com/samples/riverview-residences/fallback/community-space.jpeg"
									alt="Community Events"
									loading="lazy"
									className="w-full object-cover md:order-1"
								/>
							</picture>
						</div>
						<div className="md:flex-1">
							<h2 className="text-2xl font-semibold text-purple mb-4">Vibrant Community Events</h2>
							<p className="mb-4 leading-relaxed">
								Immerse yourself in the vibrant pulse of Riverview Residences, where our community events are the
								cornerstone of resident interaction. Our diverse gatherings, from social mixers to cultural events, are
								designed to foster connections, enrich experiences, and create lasting memories. These events are not
								just fun; they&rsquo;re a pathway to building a supportive and integrated community.
							</p>
							<p className="mb-4 leading-relaxed">
								Our meticulously curated events aim to cultivate a strong sense of belonging and mutual support. Engage
								in activities that promote personal growth and communal well-being, from wellness workshops to
								educational seminars. At Riverview Residences, every event is an opportunity to enhance your network,
								learn, and thrive within a vibrant community setting.
							</p>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}
